body{
    margin: 0;
    font-family: 'Roboto', sans-serif;
    color: white;
    background: #111111;
  }
  #crosshair{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2%;
    transform: translate(-50%, -50%);
  }
  #ammo_container{
    position: absolute;
    right: 5%;
    bottom: 5%;
    font-size: 3em;
  }
  #progress{
    position: absolute;
    bottom: 9%;
    left: 0;
    width: 0;
    height: 2%;
    background-color: #49ca22;
  }
  #health_container{
    position: absolute;
    bottom: 8%;
    left: 5%;
    width: 18%;
    height: 2%;
    border: 1px solid #ffffff;
  }
  #health_progress{
    position: absolute;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
  }

  #scoreboard{
    position: absolute;
    top: 25px;
    left: 25px;
    width: 300px;
    height: 400px;
    display: none;
  }

  #table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  #scoreboard tr {
    border-radius: 10px;
  }

  #scoreboard tr td {
    width: 50%;
    background-color: white;
    padding: 10px;
    color:black;
  }
  #game_hud{
    visibility: hidden;
  }
  #menu{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #111111;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  h1{
    font-family: 'Libre Barcode 39 Text', cursive;
    font-size: 4em;
  }
  button{
    background-color: rgba(0,0,0,0);
    color: rgb(221, 221, 221);
    font-size: 1.5em;
    border: none;
    cursor: pointer;
    margin-top: 10%;
    font-weight: 700;
    visibility: hidden;
  }
  #debug{
    position: absolute;
    right: 5%;
    top: 5%;
  }

  #logo {
    width: 640px;
  }